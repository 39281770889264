/* eslint-disable max-len */
import React from 'react';

export default function BarrelIcon({ size = 18, color }) {
  return (
    <svg width={size} height={size} fill={color} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="m434.6,97.4c0-56.7-89.1-86.4-177.2-86.4s-177.1,29.7-177.1,86.4c-1.42109e-14,1.8-1.42109e-14,317.2-1.42109e-14,317.2l0,0c0,56.7 89.1,86.4 177.2,86.4s177.2-29.7 177.2-86.4l0,0c-0.1,0-0.1-314.2-0.1-317.2zm-40.8,158.6c0,15.5-48.1,45.6-136.3,45.6s-136.4-30.1-136.4-45.6v-101.4c34.2,19.3 85.4,29.2 136.3,29.2s102.1-9.9 136.3-29.2v101.4zm-136.2-204.2c88.2-1.42109e-14 136.3,30.1 136.3,45.6s-48.1,45.6-136.3,45.6-136.3-30.1-136.3-45.6 48-45.6 136.3-45.6zm0,408.4c-88.2,0-136.3-30.1-136.3-45.6l0,0v-101.4c34.2,19.3 85.4,29.3 136.3,29.3 50.9,0 102.1-9.9 136.3-29.3v101.4l0,0c0,15.5-48.1,45.6-136.3,45.6z" />
    </svg>

  );
}

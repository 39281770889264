import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Home from 'components/Home';
import { initI18next } from 'i18n/i18next';
import { FirebaseAppProvider } from 'reactfire';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'inscription.digitank.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:914efbce0f717e60297452',
  measurementId: 'G-58KZH4JBR3',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#3f4d5a',
    },
    info: {
      main: '#437A89',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    flashyGreen: {
      main: '#1db954',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
  },
});

function App() {
  initI18next();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Container>
              <CssBaseline />
              <Home />
            </Container>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    </FirebaseAppProvider>

  );
}

export default App;

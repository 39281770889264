import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export default function CookiesDetailDialog(props) {
  const {
    open, setOpen, t, agreeToCookies,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-rgpd-details"
      aria-describedby="dialog-rgpd-all-details"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-rgpd">
          {t('rgpd.our_message', { joinArrays: '' })}
        </DialogContentText>
        <DialogContentText sx={{ mt: 3 }}>
          {t('rgpd.cnil_info')}
          <Link
            href="https://www.cnil.fr/fr/cookies-et-autres-traceurs"
            color="#335C67"
          >
            {t('rgpd.cnil_site')}
          </Link>
        </DialogContentText>
        <DialogContentText sx={{ mt: 3, whiteSpace: 'pre-line' }}>
          {t('rgpd.trackers', { joinArrays: '\n' })}
        </DialogContentText>
        <DialogContentText sx={{ mt: 3 }}>
          {t('rgpd.browser')}
        </DialogContentText>
        <DialogContentText sx={{ mt: 1 }}>
          {t('rgpd.browser_instructions')}
        </DialogContentText>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          <Link
            href="https://support.google.com/accounts/answer/61416?hl=fr"
            color="#335C67"
          >
            Chrome
          </Link>
          <Link
            href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies"
            color="#335C67"
          >
            Firefox
          </Link>
          <Link
            href="http://windows.microsoft.com/fr-fr/internet-explorer/delete-manage-cookies"
            color="#335C67"
          >
            Internet Explorer
          </Link>
          <Link
            href="https://help.opera.com/en/latest/web-preferences/#cookies"
            color="#335C67"
          >
            Opera
          </Link>
          <Link
            href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
            color="#335C67"
          >
            Safari
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('close')}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => agreeToCookies()}
        >
          {t('rgpd.agreed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

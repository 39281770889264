import React from 'react';
import { Snackbar, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

export default function CookiesWarning(props) {
  const {
    setcookiesDetailOpen, t, openSnackBar, agreeToCookies,
  } = props;

  return (
    <Snackbar
      open={openSnackBar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        style={{ width: '100%' }}
        severity="info"
        action={(
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => agreeToCookies()}
            >
              {t('rgpd.agreed')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setcookiesDetailOpen(true)}
            >
              {t('rgpd.details')}
            </Button>
          </Box>
        )}
      >
        {t('rgpd.short_message')}
      </Alert>
    </Snackbar>
  );
}

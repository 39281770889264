import {
  Box, Button, Link, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Truck from '@mui/icons-material/LocalShipping';
import BarrelIcon from 'icons/BarrelIcon';
import Footer from './Footer';
import Cookies from './cookies/Cookies';

export default function Home() {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 10, gap: 5, minHeight: '80vh',
      }}
      >
        <Typography variant="h3">
          {t('home_title')}
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          flexWrap: 'wrap',
        }}
        >
          <Button
            color="info"
            sx={{
              p: 2, gap: 2, display: 'flex', flexDirection: 'column', width: 300,
            }}
            variant="contained"
            href="https://gc.digitank.labox-apps.com/onboarding"
          >
            <BarrelIcon size={150} color="#FFFFFF" />
            {t('app_name.digitank-gauged-containers')}
          </Button>
          <Button
            disabled
            color="info"
            sx={{
              p: 2, gap: 2, display: 'flex', flexDirection: 'column', width: 300,
            }}
            variant="contained"
          >
            <Truck sx={{ fontSize: 150 }} />
            {t('app_name.digitank-tanker-trucks')}

          </Button>
        </Box>
        <Typography variant="h6">
          {t('discover_our_products')}
          <Link
            component={Link}
            href="https://labox-apps.com"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            labox-apps.com
          </Link>
        </Typography>
      </Box>
      <Footer />
      <Cookies
        cookieName="agreed-to-cookies-on-labox-inscription-digitank"
        t={t}
        globalActions={{ setCookiesAccepted: () => {} }}
      />
    </Box>
  );
}

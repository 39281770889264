import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CookiesWarning from './CookiesWarning';
import CookiesDetailDialog from './CookiesDetailDialog';

export default function Cookies(props) {
  const { globalActions, t, cookieName } = props;
  const [cookiesDetailOpen, setcookiesDetailOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    const agreed = localStorage.getItem(cookieName);
    if (!agreed) {
      setOpenSnackBar(true);
    }
  }, [cookieName]);

  const agreeToCookies = () => {
    localStorage.setItem(cookieName, 'true');
    globalActions.setCookiesAccepted(true);
    setOpenSnackBar(false);
    setcookiesDetailOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CookiesWarning
        openSnackBar={openSnackBar}
        agreeToCookies={agreeToCookies}
        t={t}
        setcookiesDetailOpen={setcookiesDetailOpen}
      />
      {cookiesDetailOpen && (
        <CookiesDetailDialog
          t={t}
          open={cookiesDetailOpen}
          setOpen={setcookiesDetailOpen}
          agreeToCookies={agreeToCookies}
        />
      )}
    </Box>
  );
}
